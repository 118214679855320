import header from "@/assets/table/claim/claimEndHeader";
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import ClaimMemo from "@/components/atoms/VTooltip/ClaimMemo";
import OrderStatus from "@/assets/data/status/OrderStatus";
import ListContextMenu from "@/components/atoms/ContextMenu/ListContextMenu.vue";
import filterNormal from "@/assets/image/svg/filter.svg";
import filterActive from "@/assets/image/svg/filter_active.svg";
import { claimSearch, totalCount, claimFilters } from "@/api/claim";
import commitName from "@/components/pages/common/ApiActionName.js";

export default {
  components: {
    ClaimMemo,
    ListContextMenu,
    filterNormal,
    filterActive,
  },
  props: ["type"],
  computed: {
    ...mapGetters({
      pageList: "getEndClaimList",
      pageParams: "getEndClaimParam",
      totalCount: "getClaimTotalCount",
      sort: "getEndClaimSort",
      ariseAllChecked: "getEndAriseAllChecked",
      passAllChecked: "getEndPassAllChecked",

      monthList: "getMonthList",
      pageIndex: "getPageIndex",
      originTotalList: "getEndClaimOriginTotalList",
    }),
    searchData() {
      return this.setSearchData("search", this.names.searchCommitName);
    },
    totalData() {
      return this.setSearchData("search", this.names.totalCommitName);
    },
    infiniteScrollData() {
      return this.setSearchData("infinite", this.names.infiniteCommitName);
    },
    isPluseParamBtn() {
      const paramIndex = this.pageParams.findIndex(
        (param) => param.field === this.filterField,
      );
      return !!this.isKeyword && -1 < paramIndex;
    },
    isApplyBtn() {
      let isApply = this.isKeyword
        ? this.isPluseParam
          ? !this.isPluseParam
          : !this.keywordFilters.filter((x) => x.checked).length
        : !this.filters.filter((x) => x.checked).length;
      return isApply;
    },
  },
  data() {
    return {
      header,
      observer: null,
      names: commitName["endClaim"],

      filterField: "", // 현재 선택한 filter field
      isFilter: false,
      allChecked: false, // 모두 선택 checkbox
      keywordAllChecked: false, // keywod 검색 filter 모두 선택 check box
      filters: [], // 현재 filter 데이터
      params: [], // checked 가 true 데이터
      infinitieSearchField: "", //무한 스크롤 field
      // sort: 1, // 정렬 선택 1: 오림차순, -1 :내림 차순
      isKeyword: false, // filter keyword 입력 여부
      findKeyword: "", // filter 검색을 위한 keyword
      keywordFilters: [], // keyword 가 포함된 filter
      isPluseParam: false,

      // isEndCase: "true",

      selected: [],

      ariseHeader: [
        "arise_checkbox",
        "arise_month",
        "arise_type",
        "arise_client",
        "arise_price",
        "arise_detail",
        "arise_status",
        "arise_pass",
        "arise_passtype",
        "ariseNone",
      ],
      passHeader: [
        "pass_checkbox",
        "pass_month",
        "pass_type",
        "pass_client",
        "pass_price",
        "pass_detail",
        "pass_status",
        "pass_modify",
        "passNone",
      ],

      setFilter: {},
      firSetFilter: {
        filter: [],
        name: "",
      },
      findFilterList: [],
      searchValue: "",
      headerList: {},
      newRows: [],
      dayjs,
      ariseOnRow: {
        index: "",
        id: "",
      },
      passOnRow: {
        index: "",
        id: "",
      },
      onRow: {},
      ariseModify: {},
      passModify: {},
      menu_date_register: false,
      pass_menu_date_register: false,
      keydownListener: null,
    };
  },
  methods: {
    /**
     * api 호출
     */
    // 검색 할 값 생성
    setSearchData(type, name) {
      if (!this.pageParams.length) this.infinitieSearchField = "";
      const data = {
        param: {
          multi: JSON.stringify(this.pageParams),
          field:
            type === "infinite"
              ? this.infinitieSearchField
              : this.setFilterField(),
          sort: this.sort,
          isEndCase: this.names.isEndCase,
        },
        commitName: name,
        isInfinite: type !== "infinite",
      };
      if (type === "infinite") data.param.skip = this.pageList.length;

      return data;
    },
    // 검색 api 호출
    search() {
      this.callTotalCountAPI();
      this.callSearchAPI();
    },

    // 검색시 sort field 생성
    setFilterField() {
      let field = "";
      if (this.filterField) {
        field = this.filterField;
      }
      if (this.pageParams.length && !this.filterField) {
        field = this.pageParams[this.pageParams.length - 1].field;
      }
      return field;
    },

    // filter api 호출시 현재 필드 값을 뺴고 multi 생성
    setFilterParam() {
      let params = [];
      const filterIndex = this.pageParams.findIndex(
        (x) => x.field === this.filterField,
      );

      if (-1 < filterIndex) {
        for (let i = 0; i < filterIndex; i++) {
          params.push(this.pageParams[i]);
        }
      } else params = this.pageParams;

      return JSON.stringify(params);
    },

    // 내부 필터 데이터 호출 api
    async setFilters() {
      const head = header.find((haed) => haed.value === this.filterField);
      try {
        const result = await claimFilters({
          company: this.$store.state.auth_company,
          params: {
            field: this.filterField,
            multi: this.setFilterParam(),
            isEndCase: this.names.isEndCase,
            sort: head.sort,
          },
        });

        if (result.status !== 200) throw `error: ${result.status}`;

        const params = this.copyData(
          this.pageParams.find((x) => x.field === this.filterField),
        );

        if (!params) {
          this.filters = result.data.length ? result.data : [];
          this.setParams();
        } else {
          this.params = params.params;
          this.filters = result.data.map((x) => {
            const param = this.params.find((name) => name === x.name);
            return {
              name: x.name,
              checked: param !== undefined,
            };
          });
          this.allChecked = this.params.length === this.filters.length;
        }
      } catch (error) {
        console.log(error);
      }
    },

    // 오더 data 검색 api
    async callSearchAPI() {
      this.$store.dispatch(this.names.searchApiName, this.searchData);
      this.infinitieSearchField = this.setFilterField();
    },

    //무한스크롤 API
    async infiniteScroll() {
      this.$store.dispatch(this.names.searchApiName, this.infiniteScrollData);
    },

    //검색된 오더 total count api
    async callTotalCountAPI() {
      this.$store.dispatch(this.names.totalApiName, this.totalData);
      // this.ariseAllChecked = false;
      // this.passAllChecked = false;
    },

    //head 에 값에 따로 sort 값 전달
    headSort(field) {
      const head = header.find((haed) => haed.value === field);
      return head && head.sort ? head.sort : 1;
    },

    /**
     * 필터 이벤트
     */
    // 필터 비콘 클릭 이벤트
    filterBeaconClick(head) {
      this.filterField !== head ? this.filterSet(head) : this.initFilter();
      this.findKeyword = "";
      this.params = [];
    },

    //필터 초기화 기능
    initFilter() {
      this.filterField = "";
      this.findKeyword = "";
      this.isFilter = false;
      this.allChecked = false;
      this.isKeyword = false;
      this.filters = [];
      this.keywordFilters = [];
    },

    // 필터 set 기능
    filterSet(head) {
      this.filterField = head;
      this.isFilter = true;
      this.filters = [];
      this.setFilters();
    },

    // 필터 활성화시 최초 params set
    setParams() {
      this.params = this.filters.filter((x) => x.checked).map((x) => x.name);
      this.allChecked = this.params.length === this.filters.length;
    },

    allChekedClick() {
      this.allChecked = !this.allChecked;
      this.filters = this.filters.map((x) => {
        x.checked = this.allChecked;
        return x;
      });
      this.params = this.allChecked
        ? this.filters.filter((x) => x.checked).map((x) => x.name)
        : [];
    },

    // checkbox 클릭시 이벤트
    checkBoxClick(i) {
      this.filterChecked(i);
      this.modifyParams(i);
      this.allChecked = this.params.length === this.filters.length;
    },

    // filter checked 변경
    filterChecked(i) {
      const filters = this.copyData(this.filters);
      filters[i].checked = !filters[i].checked;
      this.filters = filters;
    },

    // 현재 활성화된 filter 검색 params set
    modifyParams(i) {
      const filter = this.filters[i];
      if (filter.checked) {
        this.params.push(filter.name);
        this.params = [...new Set(this.params)];
      } else {
        const index = this.params.findIndex((x) => x === filter.name);
        this.params.splice(index, 1);
      }
    },

    // 필터 적용
    applyBtnClick() {
      this.$store.commit(
        this.names.sortCommitName,
        this.headSort(this.filterField),
      );
      // this.sort = this.headSort(this.filterField);
      // this.sort = 1;
      this.commitParams();
      this.search();
      // this.modifyBeacon(true);
      this.initHeader();
      this.initFilter();
    },

    // 오름, 내림 차순
    sortBtnClick(sort) {
      // this.sort = sort;
      this.$store.commit(this.names.sortCommitName, sort);
      this.search();
      this.initFilter();
    },

    // beacon checked 변경
    modifyBeacon(checked) {
      const index = header.findIndex((head) => head.value === this.filterField);
      header[index].checked = checked;
    },

    // 검색 버튼 클릭시 멀티 params 값 변경
    commitParams() {
      const pageParams = this.copyData(this.pageParams);
      const paramIndex = this.pageParams.findIndex(
        (param) => param.field === this.filterField,
      );

      const params = {
        field: this.filterField,
        params: this.params,
      };
      if (paramIndex < 0) pageParams.push(params);
      else pageParams[paramIndex] = params;

      this.$store.commit("setEndClaimParam", pageParams);
    },

    monthFilter(month, type) {
      const list = this.filters.filter(
        (date) => this.monthParse(date.name, type) === month,
      );

      if (list.length) {
        this.params = list.map((date) => date.name);
        this.applyBtnClick();
      } else this.initParams();
    },

    monthParse(date, type) {
      let parseDate = date ? date : "";

      if (type === "mmdd") parseDate = parseDate.substr(0, 2);

      if (type === "yymm") parseDate = parseDate.substr(3, 5);

      if (type === "yyyymm") parseDate = parseDate.substring(5, 7);

      if (type === "m" && +date < 10) parseDate = `0${parseDate}`;

      return parseDate;
    },

    // 필터 해제
    initParams() {
      this.params = [];
      const orderParams = this.copyData(this.pageParams);
      const index = orderParams.findIndex(
        (param) => param.field === this.filterField,
      );
      orderParams.splice(index, 1);
      this.$store.commit(this.names.paramName, orderParams);
      this.setFilters();
      this.initFilter();
      this.$store.commit(
        this.names.sortCommitName,
        this.headSort(this.setFilterField()),
      );
      this.search();
      this.initHeader();
    },

    /**
     * 페이지 출력 함수 모음
     */

    // 숫자 필터 사용시 검색 함수
    numFiltering(field, pageParams) {
      this.filterField = field;
      this.$store.commit(this.names.paramName, pageParams);
      this.search();
      this.initHeader();
      this.initFilter();
    },

    // 숫자 필터 다이어로그 open
    numDialogOpen(type) {
      this.$store.dispatch("NUM_DIALOG_OPEN", {
        field: this.filterField,
        filters: this.copyData(this.filters),
        params: this.copyData(this.pageParams),
        type: type,
        numFiltering: this.numFiltering,
      });
    },

    /**
     * 필터 검색
     */

    // 입력된 keyword 를 filter List 검색
    inputKeyowrd() {
      this.isPluseParam = false;
      this.isKeyword = !!this.findKeyword;
      this.keywordFilters = this.copyData(
        this.filters.filter((x) =>
          String(x.name).toLowerCase().includes(this.findKeyword.toLowerCase()),
        ),
      );
      this.keywordAllChecked = this.keywordFilters.length
        ? !this.keywordFilters.find((x) => !x.checked)
        : false;
    },

    // 입력된 keyword filter checkbox 클릭
    keywordCheckBoxClick(i) {
      this.keywordFilters[i].checked = !this.keywordFilters[i].checked;
      const index = this.filters.findIndex(
        (x) => x.name === this.keywordFilters[i].name,
      );
      this.checkBoxClick(index);
    },

    // 입력된 keyword filter 전체버튼 클릭
    keywordAllcheckedClick() {
      const params = this.params;
      this.keywordAllChecked = !this.keywordAllChecked;
      this.keywordFilters = this.keywordFilters.map((x) => {
        x.checked = this.keywordAllChecked;
        return x;
      });
      this.keywordFilters.forEach((filter) => {
        if (this.keywordAllChecked) {
          params.push(filter.name);
        } else {
          const index = this.params.findIndex((x) => x === filter.name);
          params.splice(index, 1);
        }
      });
      this.params = [...new Set(params)];
    },

    keywordApplyBtnClick() {
      if (!this.isPluseParam) {
        const keywordParam = this.keywordFilters.filter((x) => x.checked);
        this.params = keywordParam.length
          ? keywordParam.map((x) => x.name)
          : this.params;
      }
      this.applyBtnClick();
    },

    async checkScroll() {
      const scroll = this.$refs.endPoint;
      this.observer = new IntersectionObserver(
        async ([entry]) => {
          if (entry.isIntersecting && this.pageList.length < this.totalCount) {
            this.infiniteScroll();
          }
        },
        {
          threshold: 0.5,
        },
      );
      this.observer.observe(scroll);
    },

    initHeader() {
      header.map((head) => {
        head.checked = !!this.pageParams.find(
          (param) => param.field === head.value,
        );
        return head;
      });
    },

    async pageMove() {
      this.pageParams.splice(0, 1);
      this.$store.commit("setEndClaimParam", this.pageParams);
      await this.callTotalCountAPI();

      if (this.totalCount) this.callSearchAPI();
      else {
        this.$store.commit("setEndClaimParam", []);
        this.search();
      }

      this.initHeader();
    },

    statusParse(item) {
      return OrderStatus[item];
    },

    //////////////////////////////////////////////////////////////////////////////////////////

    allCheckItem(type) {
      if (type === "arise_checkbox") {
        // this.$store.commit("setCacSalesCheckBox", !this.ariseAllChecked);
        // this.ariseAllChecked = !this.ariseAllChecked;
        this.checkBoxCommit("arise", !this.ariseAllChecked);
        this.pageList.forEach((x) => {
          if (x.arise_id && x.arise_status === "00")
            x.arise_checked = this.ariseAllChecked;

          if (
            this.ariseAllChecked &&
            x.arise_checked &&
            x.arise_status === "00" &&
            !this.$store.state.ariseSelected.includes(x)
          ) {
            x.saveTarget = "arise";
            // this.$store.state.ariseSelected.push(x);
          }
        });
        // if (!this.ariseAllChecked) this.$store.state.ariseSelected = [];
      } else {
        // this.passAllChecked = !this.passAllChecked;
        this.checkBoxCommit("pass", !this.passAllChecked);
        this.pageList.forEach((x) => {
          if (x.pass_id && x.pass_status === "00")
            x.pass_checked = this.passAllChecked;

          if (
            this.passAllChecked &&
            x.pass_checked &&
            x.pass_status === "00" &&
            !this.$store.state.passSelected.includes(x)
          ) {
            x.saveTarget = "pass";
            // this.$store.state.passSelected.push(x);
          }
        });
        // if (!this.passAllChecked) this.$store.state.passSelected = [];
      }
      this.setCheckBoxLength();
    },
    checkItem(item, i, type) {
      let isAllCheck = "";
      if (type === "arise") {
        item.arise_checked = !item.arise_checked;
        item.arise_index = i;
        if (item.arise_checked) item.saveTarget = "arise";

        isAllCheck =
          this.pageList.filter((x) => x.arise_checked).length ===
          this.pageList.filter((x) => x.arise_id && x.arise_status === "00")
            .length;

        this.checkBoxCommit("arise", isAllCheck);
      } else {
        item.pass_checked = !item.pass_checked;
        item.pass_index = i;
        if (item.pass_checked) item.saveTarget = "pass";

        isAllCheck =
          this.pageList.filter((x) => x.pass_checked).length ===
          this.pageList.filter((x) => x.pass_id && x.pass_status === "00")
            .length;

        this.checkBoxCommit("pass", isAllCheck);
      }
      this.setCheckBoxLength();
    },

    checkBoxCommit(type, isBox) {
      this.$store.commit(
        type === "arise"
          ? this.names.ariseCheckboxCommitName
          : this.names.passCheckboxCommitName,
        isBox,
      );
    },

    setCheckBoxLength() {
      this.$store.dispatch("SET_CHECKBOXLENGTH", this.pageList);
    },

    // ----------------
    clickRow(i, item, type) {
      if (type === "arise") {
        this.ariseOnRow.index = i;
        this.ariseOnRow.id = item.order;
        this.ariseModify = JSON.parse(JSON.stringify(item));
        this.ariseModify.index = i;

        this.ariseModify.arise_type =
          item.arise_type === "매입" ? 2 : item.arise_type === "매출" ? 3 : 1;
        this.ariseModify.arise_client = this.$store.state.clientList.find(
          (x) => x.name === item.arise_client,
        );
      } else {
        this.passOnRow.index = i;
        this.passOnRow.id = item.order;
        this.passModify = JSON.parse(JSON.stringify(item));

        this.passModify.index = i;
        this.passModify.pass_type =
          item.pass_type === "매입" ? 2 : item.pass_type === "매출" ? 3 : 1;
        this.passModify.pass_client = this.$store.state.clientList.find(
          (x) => x.name === item.pass_client,
        );
      }
    },
    clearRow(type) {
      if (type === "arise") {
        this.ariseOnRow = {
          index: "",
          id: "",
        };
        this.ariseModify = {};
      } else {
        this.passOnRow = {
          index: "",
          id: "",
        };
        this.passModify = {};
      }
    },

    addRow() {
      this.$store.state.claimEdit = !this.$store.state.claimEdit;
      this.claimNewRows.push({
        arise_month: dayjs().format("YYYY-MM"),
        arise_currency_sign: "₩",
        arise_detail: "",
        pass_month: dayjs().format("YYYY-MM"),
        pass_currency_sign: "₩",
        pass_detail: "",
      });
    },
    deleteRow(index) {
      this.$store.state.claimEdit = !this.$store.state.claimEdit;
      this.claimNewRows.splice(index, 1);
    },

    processClaimRowCheck(item, target) {
      if (target === "arise") {
        if (!item.liaison) {
          this.$store.commit("setSnackBar", `담당자를 선택해주세요.`);
          return false;
        }
        if (!item.arise_type) {
          this.$store.commit("setSnackBar", `구분을 선택해주세요.`);
          return false;
        }
        if (!item.arise_client) {
          this.$store.commit("setSnackBar", `업체명을 선택해주세요.`);
          return false;
        }
      } else {
        if (!item.liaison) {
          this.$store.commit("setSnackBar", `담당자를 선택해주세요.`);
          return false;
        }
        if (!item.pass_type) {
          this.$store.commit("setSnackBar", `구분을 선택해주세요.`);
          return false;
        }
        if (!item.pass_client) {
          this.$store.commit("setSnackBar", `업체명을 선택해주세요.`);
          return false;
        }
      }
      return true;
    },
    claimRowCheck(item, target) {
      if (target === "arise") {
        if (!item.order) {
          this.$store.commit("setSnackBar", `오더를 선택해주세요.`);
          return false;
        }
        if (!item.liaison) {
          this.$store.commit("setSnackBar", `담당자를 선택해주세요.`);
          return false;
        }
        if (!item.arise_type) {
          this.$store.commit("setSnackBar", `구분을 선택해주세요.`);
          return false;
        }
        if (!item.arise_client) {
          this.$store.commit("setSnackBar", `업체명을 선택해주세요.`);
          return false;
        }
      } else {
        if (!item.order) {
          this.$store.commit("setSnackBar", `오더를 선택해주세요.`);
          return false;
        }
        if (!item.liaison) {
          this.$store.commit("setSnackBar", `담당자를 선택해주세요.`);
          return false;
        }
        if (!item.pass_type) {
          this.$store.commit("setSnackBar", `구분을 선택해주세요.`);
          return false;
        }
        if (!item.pass_client) {
          this.$store.commit("setSnackBar", `업체명을 선택해주세요.`);
          return false;
        }
      }

      return true;
    },
    modifyClaimRowCheck(target) {
      if (target === "arise") {
        if (!this.ariseModify.order) {
          this.$store.commit("setSnackBar", `오더를 선택해주세요.`);
          return false;
        }
        if (!this.ariseModify.liaison) {
          this.$store.commit("setSnackBar", `담당자를 선택해주세요.`);
          return false;
        }
        if (!this.ariseModify.arise_type) {
          this.$store.commit("setSnackBar", `구분을 선택해주세요.`);
          return false;
        }
        if (!this.ariseModify.arise_client) {
          this.$store.commit("setSnackBar", `업체명을 선택해주세요.`);
          return false;
        }
      } else {
        if (!this.passModify.order) {
          this.$store.commit("setSnackBar", `오더를 선택해주세요.`);
          return false;
        }
        if (!this.passModify.liaison) {
          this.$store.commit("setSnackBar", `담당자를 선택해주세요.`);
          return false;
        }
        if (!this.passModify.pass_type) {
          this.$store.commit("setSnackBar", `구분을 선택해주세요.`);
          return false;
        }
        if (!this.passModify.pass_client) {
          this.$store.commit("setSnackBar", `업체명을 선택해주세요.`);
          return false;
        }
      }

      return true;
    },
    saveClaim(item, type, target) {
      if (
        (type === "create" && !this.claimRowCheck(item, target)) ||
        (type === "process" && !this.processClaimRowCheck(item, target)) ||
        (type === "modify" && !this.modifyClaimRowCheck(target))
      )
        return;

      this.$store.commit("setClaimForm", item);
      const saveTarget = {
        type: type,
        target: target,
      };
      this.$store.dispatch("TASK_CLAIM_SAVE", saveTarget);
      if (type === "modify") this.clearRow(target);
    },
    setCheckSelected() {
      const ariseSelected = [];
      const passSelected = [];

      this.pageList.forEach((x) => {
        if (x.arise_checked) ariseSelected.push(x);
        if (x.pass_checked) passSelected.push(x);
      });
      this.$store.commit("setAriseSelected", ariseSelected);
      this.$store.commit("setPassSelected", passSelected);
    },
    selectSave(type) {
      this.setCheckSelected();
      if (!this.checkSelected()) {
        this.$store.commit("setSnackBar", `마감취소 할 오더를 선택해주세요.`);
        return;
      }
      const messageType = {
        cancel:
          this.$store.state.auth_grade >= 2 ? "마감취소" : "마감취소 요청",
      };

      if (
        confirm(
          `${
            this.$store.state.ariseSelected.length +
            this.$store.state.passSelected.length
          } 건을 ${messageType[type]} 하시겠습니까?`,
        )
      ) {
        const saveTarget = {
          type: type,
        };
        this.$store.state.selected = [
          ...this.$store.state.ariseSelected,
          ...this.$store.state.passSelected,
        ];

        this.$store.dispatch("TASK_SELECT_CLAIM_SAVE", saveTarget);
      }
    },
    menuOpen(event, item, target) {
      this.$store.dispatch("CONTEXT_MENU_DATA_PARSE", {
        item: item,
        target: target,
        name: "endClaim",
        id: item[`${target}_id`],
      });
      this.$refs.menu.$children[0].open(event);
    },
  },

  async created() {
    this.$store.commit("setAriseSelected");
    this.$store.commit("setPassSelected");
    if (this.pageParams.length && this.pageParams[0].field === "pageMove")
      this.pageMove();
    else {
      this.search();
    }
  },
  mounted() {
    this.keydownListener = (e) => {
      if (e.keyCode == 27 && this.isFilter) {
        this.initFilter();
      }
    };
    document.addEventListener("keydown", this.keydownListener);

    this.checkScroll();
  },
  sockets: {
    async claim(data) {
      if (this.$store.state.auth_company === data.company)
        await this.$store.dispatch("SET_END_CLAIMLIST", {
          sortHeader: this.$store.state.applySortList[this.$route.name],
        });
    },
  },
  beforeDestroy() {
    // this.observer.disconnect();
    document.removeEventListener("keydown", this.keydownListener);
    this.keydownEventListener = null;
    this.observer.unobserve(this.$refs.endPoint);
    this.observer.disconnect();
  },
};
